import React from "react";

import { Route,
    Switch,
    useRouteMatch,
    useParams
} from "react-router-dom";

import PanelHeader from "../components/PanelHeader/PanelHeader";

import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Input,
    Button,
    Alert,
} from "reactstrap"

class Edit extends React.Component{



    render() {
        return (
            <>
                <PanelHeader size="sm" />
                <div className="content">
                    <Row>
                        <Col>
                            <Card>
                                <CardHeader>
                                    <h4 className="title">Edit campaign</h4>
                                </CardHeader>
                                <CardBody>
                                    <Switch>
                                        <Route
                                            path={`${this.props.match.path}/:cid`}
                                            component={EditCampaign}
                                        >
                                        </Route>
                                    </Switch>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
}

export default Edit;

class EditCampaign extends React.Component{

    cid = this.props.match.params.cid;

    state = {
        cidData : {},
        loaded:false,
        showAlert:false
    };

    componentDidMount() {
        fetch(`/api?function=getCidInfo&cid=${this.cid}`,)
            .then((res) => (res = res.json()))
            .then(
                (result) => {
                    this.setState({cidData:result,loaded:true});
                }
            )
    }

    render() {
        return (
            <>
                <Row className="mx-5">
                    <Col>
                        <Row className="mb-5">
                            <Col>
                                <b>Name</b>: {this.state.loaded ? this.state.cidData.name : ''}
                            </Col>
                        </Row>
                        <Row className="mb-5">
                            <Col>
                                <b>Link</b>: http://request-de.inspidsp.com/?sys=click3
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <FormGroup className="w-50">
                                <p><b>IP list:</b></p>
                                <pre>
                                    {this.state.loaded ? this.state.cidData.operator : ''}
                                </pre>
                            </FormGroup>
                        </Row>
                    </Col>
                </Row>
                <Alert
                    color="danger"

                    isOpen={this.state.showAlert}
                >
                    <span>
                        Action denied!
                    </span>
                </Alert>
            </>

        )
    }
}