import React from "react";

import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Input,
    Button,
    Alert,
} from "reactstrap";

import PanelHeader from "../components/PanelHeader/PanelHeader";

//import {today} from "./Statistic";

class Act extends React.Component{

    state = {
        dateFrom : "today",
        file:null,
        loading:false,
        loaded:false,
        success:'',
    };

    handleSubmit = () => {
        const formData = new FormData();
        formData.append('file',this.state.file[0]);
        formData.append('date',this.state.dateFrom);




        fetch('/api?function=uploadFile',{
            method: 'post',
            body: formData
        })
            .then((res => res.json()))
            .then(
                (result) => {
                    result.success ? this.setState({success:'Successfully loaded'}) : this.setState({success:'Some errors has been occurred'});
                    this.setState({loading:false,loaded:true});
                }
            )
    };

    render() {
        return (
            <>
                <PanelHeader/>
                <div className="content">
                    <Row>
                        <Col>
                            <Card>
                                <CardHeader>
                                    <h4 className="title">Upload act</h4>
                                </CardHeader>
                                <CardBody className="px-5">
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <label htmlFor="month">Chose month</label>
                                                <Input
                                                    id="month"
                                                    value={this.state.dateFrom}
                                                    onChange={e => this.setState({dateFrom:e.target.value})}
                                                    type="date"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <label htmlFor="file">Chose file</label>
                                                <Input
                                                    id="file"
                                                    onChange={e => this.setState({file:e.target.files})}
                                                    type="file"
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Button color="info" onClick={this.handleSubmit}>Upload</Button>
                                    </Row>
                                    <Row>
                                        <Alert
                                            color="success"

                                            isOpen={this.state.loading}
                                        >
                                            <span>
                                                Loading
                                            </span>
                                        </Alert>
                                        <Alert
                                            color="info"

                                            isOpen={this.state.loaded}
                                        >
                                            <span>
                                                {this.state.success}
                                            </span>
                                        </Alert>

                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
}

export default Act;