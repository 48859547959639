/*!

=========================================================
* Now UI Dashboard React - v1.4.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import { NavLink } from "react-router-dom";
import { Nav } from "reactstrap";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";


import logo from "logo.png";
import {getCookie} from "../../variables/functions";

var ps;

class Sidebar extends React.Component {

  state = {
    role:''
  }

  login = getCookie('login');

  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebar, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }

    fetch(`/api?function=getRole&login=${this.login}`,)
        .then((res => res.json()))
        .then(
            (result) => {
              this.setState({role:result.role});
            }
        )

  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  render() {
    return (
      <div className="sidebar" data-color={this.props.backgroundColor}>
        <div className="logo">
          <NavLink
            to="/admin/statistic"
            className="simple-text "
          >
            <div className="logo-img d-flex justify-content-center" >
              <img src={logo}  className={"w-75"}/>
            </div>
          </NavLink>
        </div>
        <div className="sidebar-wrapper" ref="sidebar">
          <Nav>
            {this.props.routes.map((prop, key) => {
              if (prop.redirect) return null;
              return (
                <li
                  className={
                    this.activeRoute(prop.layout + prop.path) +
                    (prop.pro ? " active active-pro" : "")
                  }
                  key={key}
                >
                  <NavLink
                    to={prop.layout + prop.path}
                    className="nav-link font-weight-bold"
                    activeClassName="active"

                  >
                    <i className={"now-ui-icons " + prop.icon} />
                    <p>{prop.name}</p>
                  </NavLink>
                </li>
              );
            })}
            {this.state.role === 'admin'
                ? <li className={this.activeRoute("/admin/act")} key={43}>
                  <NavLink
                    to="/admin/act"
                    className="nav-link font-weight-bold"
                    activeClassName="active"
                  >
                    <i className="now-ui-icons files_single-copy-04"/>
                    <p>Act</p>
                  </NavLink>
                </li>
                : ''

            }
          </Nav>
        </div>
      </div>
    );
  }
}

export default Sidebar;
