import React from "react";
import {DataChart, Main, Box, DataTable, Spinner, Text, DateInput, FormField} from "grommet";

const rowDataSelector = (state, { griddleKey }) => {
    return state
        .get('data')
        .find(rowMap => rowMap.get('griddleKey') === griddleKey)
        .toJSON();
};



const dateObj = new Date();
const year = dateObj.getUTCFullYear();
const yesterdayObj = new Date(dateObj.getTime() - 60 * 60 * 24 * 1000)
const tomorrowObj = new Date(dateObj.getTime() + 60 * 60 * 24 * 1000)

const yesterday = yesterdayObj.getUTCFullYear() + "-" + to2digits(yesterdayObj.getUTCMonth() + 1) + "-" + to2digits(yesterdayObj.getUTCDate())
const today = dateObj.getUTCFullYear() + "-" + to2digits(dateObj.getUTCMonth() + 1) + "-" + to2digits(dateObj.getUTCDate())
const tomorrow = tomorrowObj.getUTCFullYear() + "-" + to2digits(tomorrowObj.getUTCMonth() + 1) + "-" + to2digits(tomorrowObj.getUTCDate())


class Statistic extends React.Component{

    state = {
        dateFrom : today,
        dateTo : tomorrow,
        items: [],
        loaded:false,
        mounted : false,
        sum : {
            'reqs' : 0,
            'wins' : 0,
            'imps' : 0,
            'clicks' : 0,
            'spent' : 0,
        },
        campaign : "",
        chartLoaded: false,
        chartData: [],
        sort: "name",

    };


    componentDidMount() {
        this.loadStat();
        this.loadChart();
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (this.state.dateFrom !== prevState.dateFrom || this.state.dateTo !== prevState.dateTo) {
            this.loadStat();
            this.loadChart();
        }
    }



    loadStat = () => {

        console.log(this.state)

        this.setState({
            items: [],
            loaded:false,
            sum : {
                'reqs' : 0,
                'wins' : 0,
                'imps' : 0,
                'clicks' : 0,
                'spent' : 0,
            },})
        fetch(`/api?function=getStatistic&dateFrom=${this.state.dateFrom}&dateTo=${this.state.dateTo}`,)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({loaded:true,items:result});
                    result.map((row) => {
                        this.setState({sum:{
                                'reqs' : this.state.sum.reqs += row.reqs,
                                'clicks' : this.state.sum.clicks += row.clicks,
                                'wins' : this.state.sum.wins += row.wins,
                                'imps': this.state.sum.imps += row.imps,
                                'spent' : this.state.sum.spent += row.spent,
                            }});
                    })
                },
                // Примечание: Обрабатывать ошибки необходимо именно здесь
                // вместо блока catch(), чтобы не пропустить
                // исключения из реальных ошибок в компонентах.
                (error) => {
                    this.setState({loaded:true})
                }
            )
    }

    loadChart = () => {
        this.setState({
            chartLoaded : false,
            chartData: [],
        })
        fetch(`/api?function=getChart&dateFrom=${this.state.dateFrom}&dateTo=${this.state.dateTo}`,)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({chartLoaded:true,chartData:result});
                },
                // Примечание: Обрабатывать ошибки необходимо именно здесь
                // вместо блока catch(), чтобы не пропустить
                // исключения из реальных ошибок в компонентах.
                (error) => {
                    this.setState({loaded:true})
                }
            )
    }

    setSort = (sort)=> {
        this.setState({sort:sort})
    }

    setDateFrom = event => {
        this.setState({dateFrom:event.value})
    }

    setDateTo = event => {
        this.setState({dateTo:event.value})
    }


    render() {
        let data = [{ date: '2020-08-20', amount: 2 }, { date: '2020-08-21', amount: 47 }, { date: '2020-08-22', amount: 33 }];
        return (
            <Main background={"white"}>
                <Box width={"100vw"} justify={"center"} align={"center"} margin={"medium"}>
                    {this.state.chartLoaded ? <DataChart
                        data={this.state.chartData}
                        series={['date', {property: 'spent', prefix: '$'}]}
                        chart={[
                            {
                                property: 'spent',
                                type: 'line',
                                thickness: 'xxsmall',
                                color: "#203260"
                            },
                            {property: 'spent', type: 'point', thickness: 'xsmall'}
                        ]}
                        guide={{x: {granularity: 'fine'}, y: {granularity: "medium"}}}
                        legend={true}
                        size={{width: "xlarge"}}
                        detail={true}
                    /> :
                        <Box
                            fill
                            align="center"
                            justify="center"
                            direction="row"
                            pad="large"
                            gap="small"
                            background={{ color: 'background-front', opacity: 'strong' }}
                        >
                            <Spinner />
                            <Text weight="bold">Loading ...</Text>
                        </Box>
                    }
                </Box>
                <Box justify={"around"} align={"center"} direction={"row"} width={"large"} margin={"auto"}>
                    <Box width={"small"}>
                        <FormField name="date-from" label="Date From" required>
                            <DateInput
                                format="mm/dd/yyyy"
                                value={this.state.dateFrom}
                                onChange={this.setDateFrom}
                                name={"date-from"}
                            />
                        </FormField>
                    </Box>
                    <Box width={"small"}>
                        <FormField name="date-to" label="Date to" required>
                            <DateInput
                                format="mm/dd/yyyy"
                                value={this.state.dateTo}
                                onChange={this.setDateTo}
                                name={"date-to"}
                            />
                        </FormField>
                    </Box>
                </Box>
                <Box justify={"center"} align={"center"} margin={{bottom:"large"}}>
                    {!this.state.loaded ?
                    <Box
                        fill
                        align="center"
                        justify="center"
                        direction="row"
                        pad="large"
                        gap="small"
                        background={{ color: 'background-front', opacity: 'strong' }}
                    >
                        <Spinner />
                        <Text weight="bold">Loading ...</Text>
                    </Box>
                    :
                    <DataTable
                        columns={columns.map(c => ({
                            ...c,
                            search: c.property === 'name' || c.property === 'location',
                        }))}
                        data={this.state.items}
                        sort={this.state.sort}
                        onSort={this.setSort}
                        resizeable
                        step={10}
                        paginate

                    />}
                </Box>

            </Main>
        )
    }
}


export default Statistic

function to2digits(number) {
    if (number < 10)
        return '0' + number;

    return number
}

export const columns = [
    {
        property: 'name',
        header: 'Campaign',
        primary: true,
        footer: 'Total',
    },
    {
        property: 'reqs',
        header: 'Requests',
        render: datum => amountFormatter.format(datum.reqs),
        aggregate: 'sum',
        footer: { aggregate: true },
    },
    {
        property: 'imps',
        header: 'Impressions',
        render: datum => amountFormatter.format(datum.imps),
        aggregate: 'sum',
        footer: { aggregate: true },
    },
    {
        property: 'clicks',
        header: 'Clicks',
        render: datum => amountFormatter.format(datum.clicks),
        aggregate: 'sum',
        footer: { aggregate: true },
    },
    {
        property: 'spent',
        header: 'Spent',
        render: datum => spentFormatter.format(datum.spent),
        aggregate: 'sum',
        footer: { aggregate: true },
    },
    /*{
        property: 'percent',
        header: 'Percent Complete',
        render: datum => (
            <Box pad={{ vertical: 'xsmall' }}>
                <Meter
                    values={[{ value: datum.percent }]}
                    thickness="small"
                    size="small"
                />
            </Box>
        ),
    },*/
];

export const spentFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
});

const amountFormatter = new Intl.NumberFormat('en-US');