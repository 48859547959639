/*!

=========================================================
* Now UI Dashboard React - v1.4.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

// reactstrap components
import { Route, Switch, Redirect } from "react-router-dom";

import Edit from "../views/Edit";

import "../css/css.scss"

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";

import {getCookie} from "../variables/functions";
import Login from "../views/Login";
import Act from "../views/Act";

import {MuiThemeProvider,createMuiTheme} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";


var ps;

class Dashboard extends React.Component {
  state = {
    backgroundColor: "blue",
    logIn:false,
    loaded:false,
    password:'',
    role:''
  };

  sessionId = getCookie('sessionid');
  login = getCookie('login');

  mainPanel = React.createRef();
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      // ps = new PerfectScrollbar(this.mainPanel.current);
      document.body.classList.toggle("perfect-scrollbar-on");
    }

    fetch(`/api?function=isAuthorized&login=${this.login}&sessionid=${this.sessionId}`)
        .then(res => res.json())
        .then(
            (result) => {
              this.setState({logIn:result.authorized,loaded:true});
            },
            (error) => {
              this.setState({logIn:false,loaded:true});
            }
        );


  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      // ps.destroy();
      document.body.classList.toggle("perfect-scrollbar-on");
    }
  }
  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.mainPanel.current.scrollTop = 0;
    }
  }
  handleColorClick = (color) => {
    this.setState({ backgroundColor: color });
  };




  render() {

    if (!this.state.logIn && this.state.loaded)
      return (<Login/>);

    if (!this.state.loaded)
      return (
          <h1>...</h1>
      )

    return (
        <>
          <div className="wrapper">
            <div className="main-panel w-100" ref={this.mainPanel}>
              <DemoNavbar {...this.props}/>
              <Switch>
                {routes.map((prop, key) => {
                  return (
                      <Route
                          path={prop.path}
                          component={prop.component}
                          key={key}
                      />
                  );
                })}
                <Route
                    path="/edit"
                    component={Edit}
                    key={42}
                />
                <Route
                    path="/act"
                    component={Act}
                    key={43}
                />
                <Redirect from="/" to="/stats" />
              </Switch>
              <Footer fluid />
            </div>
          </div>
        </>
    );
  }
}

export default Dashboard;
