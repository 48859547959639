/*!

=========================================================
* Now UI Dashboard React - v1.4.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";


class Contacts extends React.Component {

    render() {
        return (
            <>
                <PanelHeader size="sm" />
                <div className="content">
                    <Row>
                        <Col md={2}/>
                        <Col md={8}>
                            <Card>
                                <CardHeader>
                                    <h5 className="title">Contacts</h5>
                                </CardHeader>
                                <CardBody>
                                    <p>CLICKOMATICA LLP</p>
                                    <p>Company number OC414969</p>
                                    <p>Registered office address <br/>First Floor, 85 Great Portland Street, London, United Kingdom, W1W 7LT</p>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default Contacts;
