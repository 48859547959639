/*!

=========================================================
* Now UI Dashboard React - v1.4.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col, Alert,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";

import {getCookie,setCookie} from "../variables/functions";

class User extends React.Component {

  state = {
    login:getCookie('login'),
    loginPrev: getCookie('login'),
    password: '',
    passwordConfirmed : '',
    passwordPass : null,
    changed:false,
  };


  componentDidMount() {
    fetch(`/api?function=getLogin&login=${this.state.login}`,)
        .then((res => res.json())  )
        .then(
            (result) => {
              this.setState({login:result.login});
              this.setState({password:result.password});
              this.setState({passwordConfirmed:result.password});
            }
        )
  }

  handleChange = () => {
    this.setState({changed:false});
    if (this.state.password === this.state.passwordConfirmed) {
      this.setState({passwordPass:true});
      fetch(`/api?function=changePassword&login=${this.state.login}&password=${this.state.password}&loginPrev=${this.state.loginPrev}`,)
          .then((res => res.json()))
          .then(
              (result) => {
                this.setState({loginPrev:this.state.login,changed:true});
                setCookie('sessionid',result.sessionid);
                setCookie('login',this.state.login);
              }
          )

    } else {
      this.setState({passwordPass:false})
    }
  };

  render() {
    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col md="8">
              <Card>
                <CardHeader>
                  <h5 className="title">Edit Profile</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-1" md="5">
                        <FormGroup>
                          <label>Username</label>
                          <Input
                              value={this.state.login}
                              placeholder="Username"
                              type="text"
                              onChange={e => this.setState({login: e.target.value})}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="px-1" md="3">
                        <FormGroup>
                          <label htmlFor="password">
                            Password
                          </label>
                          <Input
                              placeholder="Password"
                              type="password"
                              id="password"
                              value={this.state.password}
                              onChange={e => this.setState({password: e.target.value})}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pl-1" md="4">
                        <FormGroup>
                          <label htmlFor="passwordConfirm">
                            Confirm password
                          </label>
                          <Input
                              placeholder="Confirm password"
                              type="password"
                              id="passwordConfirm"
                              value={this.state.passwordConfirmed}
                              onChange={e => this.setState({passwordConfirmed: e.target.value})}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Alert
                            color="danger"

                            isOpen={this.state.passwordPass === false}
                        >
                            <span>
                                Password mismatch
                            </span>
                        </Alert>
                        <Alert
                            color="success"

                            isOpen={this.state.changed === true}
                        >
                            <span>
                                Successfully changed
                            </span>
                        </Alert>
                        <Button color="info" onClick={this.handleChange}>Change credentials</Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card className="card-user">
                <div className="image">
                  <img alt="..." src={require("assets/img/bg5.jpg")} />
                </div>
                <CardBody>
                  <div className="author">
                      <img
                        alt="..."
                        className="avatar border-gray"
                        src={require("assets/img/logo.png")}
                      />
                      <h5 className="title">{this.state.login}</h5>
                  </div>
                  <p className="description text-center">
                    User
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default User;
