import React from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Table,
    Row,
    Col,
    Button,
    UncontrolledTooltip,
    Input
} from "reactstrap";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Divider from "@material-ui/core/Divider";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";


class Create extends React.Component {
    render() {
        return (
            <Row className={"mt-100"}>
                <Col xs={12}>
                    <Card>
                        <CardHeader>
                            <h4 align="center">
                                Create new campaign
                            </h4>
                        </CardHeader>
                        <CardBody className={"create-campaign"}>
                            <Row>
                                <Col xs={6}>Enter short description</Col>
                                <Col xs={6}>
                                    <Input/>
                                </Col>
                            </Row>
                            <Divider />
                            <Row>
                                <Col xs={6}>Enter ip list</Col>
                                <Col xs={6}>
                                    <TextareaAutosize aria-label="minimum height" rowsMin={3} placeholder="Ip List" />
                                </Col>
                            </Row>
                            <Divider />
                            <Row>
                                <Col xs={6}>Choose os targeting</Col>
                                <Col xs={6}>
                                    <Select
                                        id="os-target"
                                        defaultValue={1}
                                    >
                                        <MenuItem value={1}>Windows</MenuItem>
                                        <MenuItem value={2}>Android</MenuItem>
                                        <MenuItem value={3}>iOs</MenuItem>
                                        <MenuItem value={4}>Linux</MenuItem>
                                    </Select>
                                </Col>
                            </Row>
                            <Divider />
                            <Row>
                                <Col xs={6}>Choose device type targeting</Col>
                                <Col xs={6}>
                                    <Select
                                        id="dt-target"
                                        defaultValue={1}
                                    >
                                        <MenuItem value={1}>Tablet</MenuItem>
                                        <MenuItem value={2}>Phone</MenuItem>
                                        <MenuItem value={3}>PC</MenuItem>
                                    </Select>
                                </Col>
                            </Row>
                            <Divider />
                            <Row>
                                <Col xs={6}>Choose browser targeting</Col>
                                <Col xs={6}>
                                    <Select
                                        id="b-target"
                                        defaultValue={1}
                                    >
                                        <MenuItem value={1}>Chrome</MenuItem>
                                        <MenuItem value={2}>Safari</MenuItem>
                                        <MenuItem value={3}>Opera</MenuItem>
                                        <MenuItem value={4}>Edge</MenuItem>
                                        <MenuItem value={5}>FireFox</MenuItem>
                                    </Select>
                                </Col>
                            </Row>
                            <Button color={"primary"}>Save</Button>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )
    }
}

export default Create