import React, {useEffect} from 'react';


import {Grommet, Box, List, Nav, Spinner, Text, Heading, Anchor} from 'grommet';
import { More } from 'grommet-icons';
import { grommet } from 'grommet/themes';
import {spentFormatter} from "./Statistic";
import {Download} from "grommet-icons"

const data = [];

for (let i = 0; i < 40; i += 1) {
    data.push({
        entry: `entry-${i + 1}`,
    });
}

class Finance extends React.Component{

    state = {
        result : {},
        loaded : false,
        error : false,
        rowsPerPage:20,
        page:0
    };

    componentDidMount() {
        fetch('/api?function=getFinance',)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({result:result});
                    this.setState({loaded:true});
                },
                (error) => {
                    this.setState({loaded:true});
                    this.setState({error:error})
                }
            );
    }


    render() {

        return (
            <Grommet theme={grommet}>
                <Box pad="large">
                    <Heading textAlign={"center"}>
                        Payments history
                    </Heading>
                    {this.state.loaded ?
                        <List
                            data={this.state.result}
                            pad={{ left: 'small', right: 'none' }}
                            action={(item, index) => {
                                if (item.act !== null) {
                                    return (
                                        <Nav>
                                            <Anchor
                                                href={`/api?function=getStats&date=${item.act}`}
                                                icon={<Download/>}
                                                target={"_blank"}
                                            />
                                        </Nav>
                                    )
                                } else {
                                    return (
                                        <Box width={"48px"}/>
                                    )
                                }
                            }}
                            step={10}
                            paginate={true}
                        >
                            {
                                datum => (
                                    <Box direction={"row"} justify={"between"} width={"xlarge"} height={"xxsmall"} align={"center"}>
                                        <Box width={"300px"}>{datum.date}</Box>
                                        <Box width={"300px"}>{spentFormatter.format(datum.amount)}</Box>
                                        <Box width={"300px"}>{datum.comment}</Box>
                                    </Box>
                                )
                            }
                        </List>
                        :
                        <Box
                            fill
                            align="center"
                            justify="center"
                            direction="row"
                            pad="large"
                            gap="small"
                            background={{ color: 'background-front', opacity: 'strong' }}
                        >
                            <Spinner />
                            <Text weight="bold">Loading ...</Text>
                        </Box>
                    }

                </Box>
            </Grommet>
        )
    }

}

export default Finance



