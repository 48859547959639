import React from "react"

import {Router,Redirect} from "react-router-dom"

import {setCookie} from "../variables/functions";
import {createBrowserHistory} from "history";

const hist = createBrowserHistory();

class Logout extends React.Component{


    render() {
        setCookie('login',null,{expires:-1});
        setCookie('sessionid',null,{expires:-1});
        window.location.href = "/";
        return (
            <Router history={hist}>
                <Redirect to="/"/>
            </Router>
        )
    }
}

export default Logout