import React from "react";
import {getCookie,setCookie} from "../variables/functions";
import {Redirect, Route, Router, Switch} from "react-router-dom";
import AdminLayout from "../layouts/Admin";
import {createBrowserHistory} from "history";
import logo from "./logo.svg"

import {Main,Heading,FormField,TextInput,Box,Button } from 'grommet';

const hist = createBrowserHistory();

class Login extends React.Component{

    state = {
        login: getCookie('login'),
        password:'',
        valid:false,
        sessionId:'',
        loaded: false,
    };

    logIn =  (e) => {
        e.preventDefault();
        this.setState({loaded:false});
        fetch(`/api?function=login&login=${encodeURIComponent(this.state.login)}&password=${encodeURIComponent(this.state.password)}`)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        valid:result.valid,
                        sessionId:result.sessionid,
                        loaded:true,
                    });
                },
            )
    };



    render() {
        if (this.state.valid) {
            setCookie('sessionid',this.state.sessionId,{expires:60*60*24});
            setCookie('login',this.state.login,{expires:60*60*24});
            return (
                <Router history={hist}>
                    <Switch>
                        <Route path="/" render={(props) => <AdminLayout {...props} />} />
                        <Redirect to="/stats" />
                    </Switch>
                </Router>
            )
        }

        return (
            <Main
                pad="large"
                background={"linear-gradient(190deg,#F2295B 25%,#203260)"}
                // height={"100vh !important"}
                width={"100vw"}
                align={"center"}
                justify={"center"}
            >
                <Box
                    pad={"medium"}
                    background={"light-1"}
                >
                    <Heading color={"#203260"}>
                        CLICKCLICSTORE
                    </Heading>
                    <FormField label="Enter your login">
                        <TextInput
                            placeholder="Login"
                            value={this.state.login}
                            onChange={e => this.setState({login:e.target.value})}
                        />
                    </FormField>
                    <FormField label="Enter your password">
                        <TextInput
                            placeholder="Password"
                            value={this.state.password}
                            type={"password"}
                            onChange={e => this.setState({password:e.target.value})}
                        />
                    </FormField>
                    <Button
                        type="submit"
                        primary
                        label="Log in"
                        color={"#203260"}
                        onClick={e => this.logIn(e)}
                    />
                </Box>
            </Main>
        )
    }
}

export default Login

//