import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles({
    root: {
        width: 275,
        background: "linear-gradient(-45deg, rgba(78, 84, 200, .9), rgba(128, 137, 255, .9))",
        color:"white",
        margin:10
    },
    title: {
        fontSize: 24,
        textAlign:"center"
    },
    pos: {
        marginBottom: 12,
    },
    btn: {
        color:"white",
        width:"100%",
    },
    mainCard: {
        display:"flex",

    },
});

export default function Docs() {
    const classes = useStyles();

    const [result,setResult] = React.useState({})
    const [loaded,setLoaded] = React.useState(false)
    const [error,setError] = React.useState(null)

    useEffect( () => {
        fetch('/api?function=getFinance',)
            .then(res => res.json())
            .then(
                (result) => {
                    setResult(result)
                    setLoaded(true)
                },
                (error) => {
                    setLoaded(true)
                    setError(error)
                }
            )
    },[])

    return (
        <Card className={classes.mainCard}>
            {
                loaded
                    ? result.map(function (act) {
                        if (act.amount < 0) {
                            const linkAct = `/api?function=getStats&date=${act.date}`;
                            return (
                                <Card className={classes.root}>
                                    <CardContent>
                                        <Typography className={classes.title} gutterBottom>
                                            {act.actDate}
                                        </Typography>

                                    </CardContent>
                                    <CardActions>
                                            <Button size="small" className={classes.btn + " button-act"}>
                                                <a href={linkAct} className={classes.a}>
                                                    <GetAppIcon/> Download Act
                                                </a>
                                            </Button>
                                    </CardActions>
                                </Card>
                            )
                        }
                    })
                    : "Loading..."}

        </Card>

    );
}
