import React from 'react';
import logo from "./logo.svg"

import {Header, Menu, Image, Box, Text} from 'grommet';
import {createBrowserHistory} from "history";
import {spentFormatter} from "../../views/Statistic";

const hist = createBrowserHistory();

export default function PersistentDrawerLeft() {

    const goTo = (path) => {
        hist.push(path)
        hist.go()
    }

    const [balance,setBalance] = React.useState(0)
    const [loaded,setLoaded] = React.useState(false)

    React.useEffect(() => {
        fetch(`/api?function=getBalance`,)
            .then(res => res.json())
            .then(
                (result) => {
                    setBalance(result.balance)
                    setLoaded(true)
                },
                // Примечание: Обрабатывать ошибки необходимо именно здесь
                // вместо блока catch(), чтобы не пропустить
                // исключения из реальных ошибок в компонентах.
                (error) => {
                    setLoaded(false)
                }
            )
    })


    return (
        <Header background="#EDF9FF">
            <Image
                width={100}
                height={50}
                src={logo}
                margin={{horizontal:"xlarge"}}
            />
            <Box direction={"row"} align={"center"}>
                <Box margin={{horizontal:"medium"}}>
                    <Text>
                        { loaded ? spentFormatter.format(balance) : ''}
                    </Text>
                </Box>
                <Menu
                    label="Statistics"
                    items={[
                        { label: 'Main', onClick: () => {goTo("stats")} },
                    ]}
                    margin={{horizontal:"small"}}
                />
                <Menu
                    label="Finance"
                    items={[
                        { label: 'Payments history', onClick: () => {goTo("balance")} },
                        { label: 'Download stats', onClick: () => {goTo("balance")} },
                    ]}
                    margin={{horizontal:"small"}}
                />
                <Menu
                    label="Settings"
                    items={[
                        { label: 'Logout', onClick: () => {goTo("logout")} },
                    ]}
                    margin={{right:"xlarge"}}
                />
            </Box>

        </Header>
    );
}
